import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.page.html',
  styleUrls: ['./static-pages.scss'],
})
export class NotFoundPage implements OnInit {

  constructor() { }

  ngOnInit() {}

}
